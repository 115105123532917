import {
  DoNotDisturb,
  SignalCellular0Bar,
  SignalCellular1Bar,
  SignalCellular2Bar,
  SignalCellular3Bar,
  SignalCellular4Bar,
  SignalWifi0Bar,
  SignalWifi1Bar,
  SignalWifi2Bar,
  SignalWifi3Bar,
  SignalWifi4Bar,
} from '@mui/icons-material';
import { Avatar, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { WithStyles, withStyles } from '@/hocs/with-styles';

const styles = (theme: Theme) => ({
  avatarGreen: {
    backgroundColor: '#59b78cff',
  },
  avatarWhite: {
    backgroundColor: '#fff',
  },
  avatarRed: {
    backgroundColor: '#ff7068ff',
  },
  iconWhite: {
    color: 'white',
  },
  iconBlack: {
    color: theme.palette.primary.main,
  },
});
interface SignalAvatarProps {
  isUnclaimed?: boolean;
  conn?: {
    strength: number;
    type: string;
  };
}
const SignalAvatar = (props: SignalAvatarProps & WithStyles<typeof styles>) => {
  const [t] = useTranslation();
  const { classes, conn, isUnclaimed } = props;
  let type = 'wifi';
  let strength = 4;
  let helperText = '';
  if (conn && conn.type) {
    type = conn.type;
  }
  if (conn && conn.strength !== undefined && conn.strength !== null) {
    strength = conn.strength;
  }
  let icon = <SignalWifi4Bar className={classes.iconBlack} />;
  let colorClass = classes.avatarWhite;
  if (isUnclaimed) {
    icon = <DoNotDisturb className={classes.iconBlack} />;
    helperText = t(['shared:deviceArchived'], { defaultValue: 'Device archived' });
  } else if (type === 'wifi') {
    helperText = t(['shared:wifiSignalStrength'], { defaultValue: 'WiFi signal strength' });
    switch (strength) {
      case 0:
        icon = <SignalWifi0Bar className={classes.iconBlack} />;
        // colorClass = classes.avatarRed;
        break;
      case 1:
        icon = <SignalWifi1Bar className={classes.iconBlack} />;
        break;
      case 2:
        icon = <SignalWifi2Bar className={classes.iconBlack} />;
        break;
      case 3:
        icon = <SignalWifi3Bar className={classes.iconBlack} />;
        break;
      case 4:
        icon = <SignalWifi4Bar className={classes.iconBlack} />;
        break;
    }
  } else if (type === 'cellular') {
    helperText = t(['shared:celularSignalStrength'], { defaultValue: 'Celular signal strength' });
    switch (strength) {
      case 0:
        icon = <SignalCellular0Bar className={classes.iconWhite} />;
        colorClass = classes.avatarRed;
        break;
      case 1:
        icon = <SignalCellular1Bar className={classes.iconBlack} />;
        break;
      case 2:
        icon = <SignalCellular2Bar className={classes.iconBlack} />;
        break;
      case 3:
        icon = <SignalCellular3Bar className={classes.iconBlack} />;
        break;
      case 4:
        icon = <SignalCellular4Bar className={classes.iconBlack} />;
        break;
    }
  }
  return <Tooltip title={helperText} children={<Avatar className={clsx(colorClass, 'hideInPercy')}>{icon}</Avatar>} />;
  // return (
  // <Tooltip
  //   title={
  //     <p>
  //       {`Connection type: ${type}`}
  //       <br />
  //       {`Signal strength: ${strength + 1}/5`}
  //     </p>
  //   }>
  //   <Avatar className={colorClass}>{icon}</Avatar>
  // </Tooltip>
  // );
};
export default withStyles(styles)(SignalAvatar);
